/* EllipsesMenuButton.css */

.menu-button {
    position: absolute;
    top: 0;
    right: 0;
  }
  
.custom-icon-button {
    color: #fff;
    background-color: #444;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.custom-icon-button:hover {
    background-color: #555;
}

.custom-menu {
    background-color: #f88989;
    color: #fff;
    min-width: 200px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.custom-menu-item:hover {
    background-color: #555;
}