/* src/main.css */
:root{
  /* dark shades of primary color*/
  --clr-primary-1: hsl(184, 91%, 17%);
  --clr-primary-2: hsl(185, 84%, 25%);
  /* primary/main color */
  --clr-primary-5: hsl(185, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  /* darkest grey - used for headings */
  --clr-1: hsl(0, 30%, 58%);
  --clr-2: hsl(0, 0%, 0%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-white: #fff;
  --ff-primary: 'Lato', sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

body {
  /* change this to something else  */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-white);
  color: var(--clr-2);
  line-height: 1.5;
  font-size: 0.875rem;
}


/*
  =============== 
  Entry
  ===============
*/
.entry-container {
  background: url('./brick_background.jpg') center/cover no-repeat;
}

/*
  =============== 
  Login & Create Account
  ===============
*/
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .credential-form {
    font-family: 'Arial', sans-serif;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
    transition: var(--transition);
  }
  .credential-form:hover {
    transform: scale(1.01)
  }

  .credential-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 94%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-button, .switch-button {
    width: 100%;
    padding: 10px;
    background-color: #926b51;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }

  .logout-button {
    width: 100px;
    padding: 10px;
    background-color: #ff5100;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1px;
    position: absolute;
    top: 0;
  }
  
  .login-button:hover, .switch-button:hover {
    background-color: #b0856a;
  } 

/*
  =============== 
  Navbar
  ===============
*/

.navbar {
  position: fixed;
  width: 100%;
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  z-index: 2;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-center {
  border-color: rebeccapurple;
}

.nav-logo {
  position: absolute;
  left: 0;
  height: 10rem; 
  width: auto; 
  margin-bottom: .5rem;
  margin-left: 0rem; 
}

.nav-header {
  text-align: center;
}
/*
  =============== 
  Footer
  ===============
*/
.footer {
  background: var(--clr-white);
  padding: 0rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
}

.footer .contact-info {
  display: flex;
  flex-direction: column;
}

.footer .contact-info p {
  margin: 0.5rem 0;
}

.footer .copyright {
  position: absolute; 
  bottom: 1rem; 
  right: 1rem; 
  text-align: right;
  flex-grow: 1;
}

/*
  =============== 
  Dashboard & Payment
  ===============
*/
.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('./home_background.jpg') center/cover no-repeat;
}

.dashboard.welcome {
  position: fixed;
  height: 50px;
  width: 97%;
  top: 0;
  z-index: 1000;
  margin: 0;
}

.dashboard {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Arial', sans-serif;
  position: relative;
}

.owed {
  background: linear-gradient(135deg, #926b51, #b3cfa3);
  border-radius: 4px;
  width: auto;
  height: 20px;
  position: absolute;
  top: 0;
  left: 5;
}

.dashboard.payment {
  font-family: 'Arial', sans-serif;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 350px;
  transition: var(--transition);
  flex-direction: column;
}

.dashboard.payment label {
  margin-bottom: 5px;
  font-weight: bold;
}

.dashboard.dashboard.payment:hover {
  transform: scale(1.01)
}

.dashboard.analytics {
  font-family: 'Arial', sans-serif;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
  transition: var(--transition);
  transition: max-height 0.5s ease;
}

.dashboard.dashboard.analytics:hover {
  transform: scale(1.01)
}

.payment-button {
  padding: 10px;
  background-color: #b0856a;
  font-size: medium;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
}
.payment-button:hover {
  background-color: #926b51;
}

.payment-button.confirm {
  background-color: #7cbb64;
  margin-right: 10px;
  width: 100px;
  height: 40px;
}

.payment-button:hover.confirm {
  background-color: #a5da90;
}

.payment-button.cancel {
  background-color: #cc6a52;
  margin-left: 10px;
  width: 70px;
  height: 40px;
}

.payment-button:hover.cancel {
  background-color: #c78676;
} 

.StripeElement {
  width: 100%;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}