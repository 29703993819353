/* transitions.css */
.fade-enter {
    opacity: 0;
    justify-content: center;
  }
.fade-enter-active {
opacity: 1;
transition: opacity 300ms;
justify-content: center;
}
.fade-exit {
opacity: 1;
justify-content: center;
}
.fade-exit-active {
opacity: 0;
transition: opacity 300ms;
justify-content: center;
}
  